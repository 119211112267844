import api from '@/base/utils/request';

// 列表数据
export const indexList = data => {
    return api({
        url: '/admin/cyc/banner_position/index',
        method: 'post',
        data
    });
};
export const isOpen = data => {
    return api({
        url: '/admin/cyc/banner_position/isOpen',
        method: 'post',
        data
    });
};