<template>
  <div class="content" v-loading="formLoading">
    <el-table style="width: 100%" class="thead-light" stripe :data="list"
      @selection-change="handleSelectionChange">
      <el-table-column prop="id" label="ID" width="60"></el-table-column>
      <el-table-column prop="position" label="位置" width="100"></el-table-column>
      <el-table-column prop="jump_type_text" label="跳转至" min-width="250"></el-table-column>
      <el-table-column prop="banner_num" label="广告数量" sortable="custom" min-width="150"></el-table-column>
      <el-table-column prop="remark" label="说明" min-width="300"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span class="dot" :class="scope.row.status_text === '已启用' ? 'replayed':'no-replay'"></span>
          <span>{{ scope.row.status_text }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="switchPosition(scope.row.id)">
            {{scope.row.status_text === '已启用' ? '关闭' : '开启'}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import { indexList, isOpen } from "../../advert/api/advert-position";
import Pagination from "@/base/components/Default/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      formLoading: false,
      requestData: {
        page_size: 15,
        page: 1,
      },
      pageData: {},
      list: [],
    };
  },
  created() {
    this.getPidList();
  },
  methods: {
    getPidList() {
      indexList(this.requestData)
        .then((res) => {
          console.log(res);
          this.list = res.data.data;
          this.pageData = res.data;
        })
        .catch((err) => {});
    },
    switchPosition(id) {
      this.fullscreen = true;
      isOpen({ id: id })
        .then((res) => {
          location.reload();
        })
        .catch((err) => {});
    },
    changPage(data) {
      this.requestData = data;
      this.getPidList();
    },
    handleSelectionChange() {},
  },
};
</script>

<style lang="scss" scoped>
.container {
  /* 列表状态小圆点 */
  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  .replayed {
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    background: rgb(8, 243, 39);
  }

  .no-replay {
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    background: rgba(255, 203, 44, 1);
  }
}
</style>