import { render, staticRenderFns } from "./advert-position.vue?vue&type=template&id=19b4082b&scoped=true&"
import script from "./advert-position.vue?vue&type=script&lang=js&"
export * from "./advert-position.vue?vue&type=script&lang=js&"
import style0 from "./advert-position.vue?vue&type=style&index=0&id=19b4082b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19b4082b",
  null
  
)

export default component.exports